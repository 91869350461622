import React from 'react';
import { graphql } from 'gatsby';
import SideMenu from '../components/side-menu';
import Root from '../components/root';

const NotFoundRoute = props => (
  <Root>
    <div>
      <SideMenu location={props.location} siteMetadata={props.data.site.siteMetadata} />
      <div className="content">
        <div className="content__inner">
          <div className="page">
            <h1 className="page__title">NOT FOUND</h1>
            <div className="page__body">
              <p>
                    You just hit a route that doesn&#39;t exist... the sadness.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Root>
);

export default NotFoundRoute;

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        siteTitle
        subtitle
        copyright
        menu {
          label
          path
          link
        }
      }
    }
  }
`;
